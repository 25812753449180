import { DatabaseItem, DatabaseRecord, income, outcome } from "./types";
import { v4 as uuidv4 } from "uuid";

const itemCount = 6;

export const generateMockData = (): DatabaseRecord => {
  const mockItems: DatabaseRecord = [];

  for (let i = 0; i <= itemCount - 1; i++) {
    const type: "income" | "outcome" = Math.random() > 0.5 ? income : outcome;

    const uuid = uuidv4();
    const trimmed = uuid.substring(0, 4);
    const coreProps = {
      description: `${trimmed}_${i.toString()}`,
      cost: Number((Math.random() * 8 + 1 + Math.random()).toFixed(2)),
      uuid,
      type,
    };

    const item: DatabaseItem = (() => {
      const randomInt = Math.floor(Math.random() * 6);

      if (randomInt === 1)
        return {
          ...coreProps,
          regularity: "MONTHLY",
          recurrenceDayNumber: Math.floor(Math.random() * 30),
        };

      if (randomInt === 2)
        return {
          ...coreProps,
          regularity: "DAILY",
        };

      if (randomInt === 3)
        return {
          ...coreProps,
          regularity: "WEEKDAYS",
        };

      if (randomInt === 4)
        return {
          ...coreProps,
          regularity: "LAST_MONTHLY_WEEKDAY",
        };

      if (randomInt === 5)
        return {
          ...coreProps,
          regularity: "WEEKLY",
          recurrenceDayNumber: Math.floor(Math.random() * 7),
        };

      if (randomInt === 6)
        return {
          ...coreProps,
          regularity: "ONE_OFF",
          recurrenceDate: new Date().toISOString(),
        };

      return {
        ...coreProps,
        regularity: "QUATERLY",
        recurrenceDate: "2024-07-24T00:00:00",
      };
    })();

    mockItems.push(item);
  }

  return mockItems;
};
