import {
  Box,
  Button,
  Center,
  Group,
  Modal,
  Notification,
  rem,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  UnstyledButton,
} from "@mantine/core";
import {
  IconChevronDown,
  IconChevronUp,
  IconEdit,
  IconSearch,
  IconSelector,
  IconTrash,
} from "@tabler/icons-react";
import { saveUserFormData } from "./firebase";
import styled from "styled-components";
import { useContext, useState } from "react";
import { Form, monthlyOptions, optionsOptions, weeklyOptions } from "./Form";
import { cloneDeep, noop, orderBy } from "lodash";
import { AppContext } from "./Context";
import {
  ANNUALLY,
  FOUR_WEEKLY,
  LAST_MONTHLY_WEEKDAY,
  MONTHLY,
  ONE_OFF,
  QUATERLY,
  WEEKDAYS,
  WEEKLY,
} from "./contants";
import { formatCurrency, formatDateToLocalString } from "./utils";
import "./index.css";
import { DatabaseItem, DatabaseRecord, TransactionType } from "./types";

const StyledTable = styled(Table)`
  // border: 1px solid;

  td,
  th {
    &#description {
      width: 200px;
    }

    &#cost {
      width: 200px;
    }

    &#date {
      width: 200px;
    }

    &#regularity {
      width: 200px;
    }

    &#buttons {
      div {
        display: flex;

        button:not(:nth-child(1)) {
          margin-left: 12px;
        }
      }
    }
  }
`;

export function DataTable({
  items,
  dataKey,
  useMockData,
}: {
  title: string;
  items: DatabaseRecord | null;
  dataKey: TransactionType;
  useMockData: boolean;
}) {
  const [filterState, setFilterState] = useState("");
  const [isOpenEditModal, setIsOpenEditModal] = useState<{
    item: DatabaseItem;
  } | null>(null);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<{
    item: DatabaseItem;
  } | null>(null);

  const [sortState, setSortState] = useState<{
    sortKey: keyof DatabaseItem;
    sortOrder: "asc" | "desc";
  }>({
    sortKey: "description",
    sortOrder: "asc",
  });

  const { setUserData, setNotification, notification } = useContext(AppContext);

  const onDeleteSubmit = async () => {
    const originalItems = cloneDeep(items || []);

    const updatedItems = originalItems.filter((item) => {
      return item.uuid !== isOpenDeleteModal?.item.uuid;
    });

    const res = await saveUserFormData(updatedItems);

    if (res.success) {
      setUserData(updatedItems);
      setIsOpenDeleteModal(null);
    } else {
      setNotification({
        message: "Unable to delete item. Please try again.",
        action: "delete",
      });
    }
  };

  const formatDate = (item: DatabaseItem) => {
    switch (item.regularity) {
      case MONTHLY: {
        return monthlyOptions.find(
          (option) => Number(option.value) === item.recurrenceDayNumber
        )?.label;
      }
      case WEEKLY: {
        return weeklyOptions.find(
          (option) => Number(option.value) === item.recurrenceDayNumber
        )?.label;
      }
      case QUATERLY:
      case ANNUALLY:
      case ONE_OFF:
      case FOUR_WEEKLY: {
        return formatDateToLocalString(item.recurrenceDate);
      }
      default: {
        return "";
      }
    }
  };

  const itemsByDataKey = (items || [])?.filter((item) => item.type === dataKey);

  const filteredItems = itemsByDataKey.filter((item) =>
    Object.values(item).some((x) => {
      return String(x).toLowerCase().includes(filterState.toLowerCase());
    })
  );

  const sortedItems = orderBy(
    filteredItems,
    [
      (item) => {
        const sortingValue = item[sortState.sortKey];
        if (typeof sortingValue === "string") {
          return sortingValue.toLowerCase();
        }

        return sortingValue;
      },
    ],
    [sortState.sortOrder]
  );

  const buttonSize = "compact-md";

  const rows = sortedItems.map((item) => {
    const formattedDate =
      item.regularity === LAST_MONTHLY_WEEKDAY
        ? "Last working day"
        : item.regularity === WEEKDAYS
        ? "Mon - Fri"
        : formatDate(item);

    const option = optionsOptions.find(
      (option) => option.value === item.regularity
    );

    const formattedRegularity =
      option && "tableLabel" in option ? option.tableLabel : option?.label;

    return (
      <Table.Tr id="row" key={item.description}>
        <Table.Td id="description">{item.description}</Table.Td>
        <Table.Td id="cost">{item.cost && formatCurrency(item.cost)}</Table.Td>
        <Table.Td id="regularity">{formattedRegularity}</Table.Td>
        <Table.Td id="date">{formattedDate}</Table.Td>
        <Table.Td id="buttons">
          <div>
            <Button
              size={buttonSize}
              onClick={() => {
                setIsOpenEditModal({
                  item,
                });
              }}
            >
              <IconEdit />
            </Button>
            <Button
              size={buttonSize}
              onClick={() => setIsOpenDeleteModal({ item })}
            >
              <IconTrash />
            </Button>
          </div>
        </Table.Td>
      </Table.Tr>
    );
  });

  const Th = ({
    id,
    sortKey,
    title,
  }: {
    id: string;
    sortKey?: keyof DatabaseItem;
    title: string;
  }) => {
    const Title = (
      <Text fw={600} fz="sm">
        {title}
      </Text>
    );

    if (!sortKey) {
      return <Table.Th id={id}>{Title}</Table.Th>;
    }

    const Icon =
      sortState.sortKey === sortKey
        ? sortState.sortOrder === "asc"
          ? IconChevronUp
          : IconChevronDown
        : IconSelector;

    return (
      <Table.Th id={id}>
        <UnstyledButton
          onClick={() =>
            setSortState({
              sortKey,
              sortOrder: sortState.sortOrder === "asc" ? "desc" : "asc",
            })
          }
        >
          <Group justify="space-between">
            {Title}
            <Center>
              <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            </Center>
          </Group>
        </UnstyledButton>
      </Table.Th>
    );
  };

  return (
    <Box
      style={{
        width: "90%",
        maxWidth: "950px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <TextInput
        withAsterisk
        onChange={(event) => setFilterState(event.target.value)}
        placeholder="Search by any field"
        mb="md"
        leftSection={
          <IconSearch
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
        }
      />
      <StyledTable captionSide="top" highlightOnHover withTableBorder>
        <Table.Thead>
          <Table.Tr>
            <Th id="description" sortKey="description" title="Description" />
            <Th id="cost" sortKey="cost" title="Amount" />
            <Th id="regularity" sortKey="regularity" title="Regularity" />
            <Th id="date" title="Date" />
            <Th id="buttons" title="" />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </StyledTable>
      <Modal
        size="80%"
        opened={Boolean(isOpenEditModal)}
        onClose={() => setIsOpenEditModal(null)}
      >
        <Form
          mode="edit"
          items={items}
          dataKey={dataKey}
          uuid={isOpenEditModal ? isOpenEditModal.item.uuid : ""}
          onClose={() => setIsOpenEditModal(null)}
          useMockData={useMockData}
        />
      </Modal>
      <Modal
        size="80%"
        opened={Boolean(isOpenDeleteModal)}
        onClose={() => setIsOpenDeleteModal(null)}
      >
        <Box mx="auto">
          <Title order={2}>Delete</Title>
          <Stack>
            <Text>
              Are you sure you want to delete {dataKey} "
              {isOpenDeleteModal?.item.description}" ?
            </Text>
            <Group>
              <Button
                variant="primary"
                onClick={() => setIsOpenDeleteModal(null)}
              >
                No, cancel
              </Button>
              <Button color="red" onClick={false ? noop : onDeleteSubmit}>
                Yes, delete
              </Button>
            </Group>
            {notification?.action === "delete" && (
              <Notification>{notification.message}</Notification>
            )}
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}
