import { LineChart } from "@mantine/charts";
import { Box, Fieldset, Grid, NumberInput, Stack, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconCircle,
  IconCurrencyPound,
  IconArrowDown,
  IconArrowUp,
} from "@tabler/icons-react";
import { TooltipProps } from "recharts";
import { generateTransactions } from "./generate-transactions";
import { add, isEqual } from "date-fns";
import { Tabs, rem } from "@mantine/core";
import { IconPhoto, IconMessageCircle } from "@tabler/icons-react";
import { List, ThemeIcon } from "@mantine/core";
import { Table } from "@mantine/core";
import classes from "./Chart.module.css";
import { formatCurrency, formatDateToLocalString } from "./utils";
import {
  DatabaseRecord,
  income,
  TransactionsByDayRecord,
  transactionsByDayRecordParser,
} from "./types";
import { useState } from "react";

const CustomTooltip = ({
  active,
  payload = [],
}: TooltipProps<number, string>) => {
  if (active && payload.length) {
    // tooltip payload type is coming as 'any' - so parse here to keep
    // accessing the object safe.

    const parsedPayload = transactionsByDayRecordParser.parse(
      payload[0].payload
    );

    return (
      <Box style={{ border: "1px solid red" }} bg="var(--mantine-color-body)">
        <Stack>
          <Text>
            {formatDateToLocalString(parsedPayload.fromDate)} |{" "}
            {formatCurrency(parsedPayload.finalBalance)}
          </Text>
          {parsedPayload.transactions.length > 0 ? (
            <ul>
              {parsedPayload.transactions.map((number: any) => {
                return (
                  <li key={number.description}>
                    <Text>
                      {number.description} ({formatCurrency(number.cost)})
                    </Text>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Text>No transactions</Text>
          )}
        </Stack>
      </Box>
    );
  }
};

export function DataChart({ items }: { items: DatabaseRecord }) {
  const chart = "chart";
  const list = "list";
  const [selectedTab, setSelectedTab] = useState<typeof chart | typeof list>(
    chart
  );

  const initialStartDate = new Date();
  const initialEndDate = add(initialStartDate, { months: 1 });

  const { getInputProps, values } = useForm<{
    startDate: Date | undefined;
    endDate: Date | undefined;
    initialBalance: string;
  }>({
    initialValues: {
      startDate: initialStartDate,
      endDate: initialEndDate,
      initialBalance: "250",
    },
  });

  const transactionData = generateTransactions({
    startDate: values.startDate,
    endDate: values.endDate,
    initialBalance: values.initialBalance,
    items,
  });

  const iconRadius = "xs";
  const iconHeight = 12;
  const iconStyle = { width: rem(iconHeight), height: rem(iconHeight) };

  const positiveIcon = (
    <ThemeIcon color="green" size={iconHeight} radius={iconRadius}>
      <IconArrowUp style={iconStyle} />
    </ThemeIcon>
  );

  const negativeIcon = (
    <ThemeIcon color="red" size={iconHeight} radius={iconRadius}>
      <IconArrowDown style={iconStyle} />
    </ThemeIcon>
  );

  const neutralIcon = (
    <ThemeIcon color="grey" size={iconHeight} radius={iconRadius}>
      <IconCircle style={iconStyle} />
    </ThemeIcon>
  );

  type PresentationalTransaction = TransactionsByDayRecord & {
    toDate: Date;
  };

  const inititalValue: PresentationalTransaction[] = [];

  const reducedTransactions = transactionData
    .map((x) => ({ ...x, toDate: x.fromDate }))
    .reduce(
      (
        accumulatedValues: PresentationalTransaction[],
        nextValue: PresentationalTransaction
      ) => {
        const accumulatedLength = accumulatedValues.length;
        if (accumulatedLength > 0) {
          if (
            nextValue.transactions.length === 0 &&
            accumulatedValues[accumulatedLength - 1].transactions.length === 0
          ) {
            const nextAccumulatedValues = accumulatedValues.map(
              (item, index) => {
                if (index === accumulatedLength - 1) {
                  return { ...item, toDate: nextValue.fromDate };
                }

                return item;
              }
            );

            return nextAccumulatedValues;
          }
        }

        return [...accumulatedValues, nextValue];
      },
      inititalValue
    );

  const rows = reducedTransactions.map((i, index) => {
    if (index === 0) {
      return (
        <Table.Tr opacity={1} key={i.fromDate.toISOString()}>
          <Table.Td colSpan={1}>
            {isEqual(i.fromDate, i.toDate) ? (
              formatDateToLocalString(i.fromDate)
            ) : (
              <div
                style={{
                  display: "inline-block",
                }}
              >
                <Stack align="center" gap={5}>
                  {formatDateToLocalString(i.fromDate)}
                  <ThemeIcon color="grey" size={iconHeight}>
                    <IconArrowDown />
                  </ThemeIcon>
                  {formatDateToLocalString(i.toDate)}
                </Stack>
              </div>
            )}
          </Table.Td>
          <Table.Td colSpan={1}>
            <List>
              <List.Item icon={neutralIcon}>Initial balance</List.Item>
            </List>
          </Table.Td>
          <Table.Td>{formatCurrency(i.finalBalance)}</Table.Td>
        </Table.Tr>
      );
    }

    if (i.transactions.length > 0) {
      return (
        <Table.Tr key={i.fromDate.toISOString()}>
          <Table.Td>{formatDateToLocalString(i.fromDate)}</Table.Td>
          <Table.Td>
            {i.transactions.map((j) => {
              return (
                <List>
                  <List.Item
                    icon={j.type === income ? positiveIcon : negativeIcon}
                  >
                    {j.description}
                    {": "}
                    {formatCurrency(j.cost)}
                  </List.Item>
                </List>
              );
            })}
          </Table.Td>
          <Table.Td>{formatCurrency(i.finalBalance)}</Table.Td>
        </Table.Tr>
      );
    }

    return (
      <Table.Tr opacity={0.5} key={i.fromDate.toISOString()}>
        <Table.Td colSpan={1}>
          {isEqual(i.fromDate, i.toDate) ? (
            formatDateToLocalString(i.fromDate)
          ) : (
            <div
              style={{
                display: "inline-block",
              }}
            >
              <Stack align="center" gap={5}>
                {formatDateToLocalString(i.fromDate)}
                <ThemeIcon color="grey" size={iconHeight}>
                  <IconArrowDown />
                </ThemeIcon>
                {formatDateToLocalString(i.toDate)}
              </Stack>
            </div>
          )}
        </Table.Td>
        <Table.Td colSpan={2}>
          <List>
            <List.Item icon={neutralIcon}>No expected transactions</List.Item>
          </List>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Box mx="auto">
      <Tabs
        mt={12}
        // orientation="vertical"
        variant="unstyled"
        value={selectedTab}
        classNames={classes}
      >
        <form>
          <Fieldset>
            <Box
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "70%",
                minWidth: "280px",
                maxWidth: "600px",
                border: "1px solid",
              }}
              // mt={24}
            >
              <Tabs.List grow>
                <Tabs.Tab
                  value={chart}
                  leftSection={<IconPhoto style={iconStyle} />}
                  onClick={() => setSelectedTab(chart)}
                >
                  Chart
                </Tabs.Tab>
                <Tabs.Tab
                  value={list}
                  leftSection={<IconMessageCircle style={iconStyle} />}
                  onClick={() => setSelectedTab(list)}
                >
                  List
                </Tabs.Tab>
              </Tabs.List>
            </Box>
            <Grid>
              <Grid.Col span={3}>
                <NumberInput
                  withAsterisk
                  leftSection={<IconCurrencyPound />}
                  label="Starting Balance"
                  {...getInputProps("initialBalance")}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <DatePickerInput
                  withAsterisk
                  label="Start Date"
                  {...getInputProps("startDate")}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <DatePickerInput
                  withAsterisk
                  label="End Date"
                  {...getInputProps("endDate")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </form>
        <Tabs.Panel pt={42} value={chart}>
          <LineChart
            h={300}
            mt="lg"
            data={transactionData.filter((i) => i.transactions.length > 0)}
            dataKey="date"
            series={[{ name: "finalBalance", color: "teal.6" }]}
            curveType="monotone"
            lineChartProps={{}}
            // tickLine="y"
            tooltipProps={{ content: CustomTooltip }}
          />
        </Tabs.Panel>

        <Tabs.Panel pt={42} value="list">
          <Table
            withTableBorder
            highlightOnHover
            mx="auto"
            style={{ width: "90%", maxWidth: "950px" }}
            stickyHeader
            stickyHeaderOffset={60}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Date</Table.Th>
                <Table.Th>Transactions</Table.Th>
                <Table.Th>Balance</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
}
