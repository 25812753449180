import { format } from "date-fns";

export const formatDateToLocalString = (date: Date | number | string) =>
  format(date, "dd/MM/yyyy");

export const formatCurrency = (amount: number) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  }).format(amount);
