import {
  addDays,
  differenceInDays,
  endOfMonth,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  isEqual,
  isSameDay,
  isWeekend,
  sub,
} from "date-fns";
import { cond, constant } from "lodash";
import {
  ANNUALLY,
  DAILY,
  FOUR_WEEKLY,
  LAST_MONTHLY_WEEKDAY,
  MONTHLY,
  ONE_OFF,
  QUATERLY,
  WEEKDAYS,
  WEEKLY,
} from "./contants";
import {
  DatabaseItem,
  DatabaseRecord,
  income,
  TransactionsByDayRecord,
} from "./types";

export type GeneratedTransaction = {
  fromDate: Date;
  initialBalance: number;
  finalBalance: number;
  transactions: DatabaseRecord;
};

export type GeneratedTransactions = GeneratedTransaction[];

// needs to handle timezones on startDate/endDate better
export const generateTransactions = ({
  startDate,
  endDate,
  initialBalance,
  items,
}: {
  startDate: Date | undefined;
  endDate: Date | undefined;
  initialBalance: string;
  items: DatabaseRecord;
}): TransactionsByDayRecord[] => {
  const openingBalance = initialBalance ? initialBalance : 0;
  if (!startDate || !endDate) {
    return [];
  }

  const transactionsByDay: TransactionsByDayRecord[] = [
    {
      fromDate: startDate,
      initialBalance: Number(openingBalance),
      finalBalance: Number(openingBalance),
      transactions: [],
    },
  ];

  const shouldAddTransaction = (
    item: DatabaseItem,
    runningDate: Date
  ): boolean => {
    switch (item.regularity) {
      case DAILY: {
        return true;
      }
      case WEEKDAYS: {
        return !isWeekend(runningDate);
      }
      case MONTHLY: {
        const dayOfMonth = getDate(runningDate);
        const isExactMatch = dayOfMonth === item.recurrenceDayNumber;

        const lastDayOfMonth = getDaysInMonth(runningDate);
        const isLastDayOfMonth = dayOfMonth === lastDayOfMonth;

        const recursAfterLastDayOfMonth =
          item.recurrenceDayNumber > lastDayOfMonth;

        return isExactMatch || (isLastDayOfMonth && recursAfterLastDayOfMonth);
      }
      case QUATERLY: {
        const targetDateTime = new Date(item.recurrenceDate);

        const targetMonth = getMonth(targetDateTime); // Jan is 0
        const currentMonth = getMonth(runningDate);
        const lastDayOfMonth = getDaysInMonth(runningDate);
        const currentDay = getDate(runningDate);
        const targetDay = getDate(targetDateTime);

        const isMatchingMonth = (currentMonth - targetMonth) % 3 === 0;

        const isLastDayOfMonth = currentDay === lastDayOfMonth;

        const isExactDayMatch = currentDay === targetDay;

        const isTargetingAfterLastDayOfMonth =
          getDate(targetDateTime) > lastDayOfMonth;

        const isExactMatch = isMatchingMonth && isExactDayMatch;

        const isApproxMatch =
          isMatchingMonth && isLastDayOfMonth && isTargetingAfterLastDayOfMonth;

        const isMatch = isExactMatch || isApproxMatch;

        return isMatch;
      }
      case FOUR_WEEKLY: {
        // every 28 days
        const daysDiff = differenceInDays(
          new Date(item.recurrenceDate),
          runningDate
        );

        const isMatch = daysDiff % (7 * 4) === 0;

        return isMatch;
      }

      case WEEKLY: {
        const runningWeekDay = getDay(runningDate);

        return runningWeekDay === item.recurrenceDayNumber;
      }

      case LAST_MONTHLY_WEEKDAY: {
        const lastDayOfMonth = endOfMonth(runningDate); // Sat 31st Aug 2024
        const lastDateOfMonthWeekdayNumber = getDay(lastDayOfMonth); // 6 (Sat)

        const daysToSub = cond([
          [(x: any) => isEqual(x, 6), constant(1)], // saturday, move back 1 day to Friday
          [(x: any) => isEqual(x, 0), constant(2)], // sunday, move back 2 days to Friday
          [() => true, constant(0)], // weekday, no need to move
        ])(lastDateOfMonthWeekdayNumber);

        const lastWorkingDayOfMonth = sub(lastDayOfMonth, {
          days: daysToSub,
        });

        return isSameDay(runningDate, lastWorkingDayOfMonth);
      }

      case ONE_OFF: {
        return isSameDay(runningDate, new Date(item.recurrenceDate));
      }

      case ANNUALLY: {
        const targetDateTime = new Date(item.recurrenceDate);

        const targetMonth = getMonth(targetDateTime); // Jan is 0
        const currentMonth = getMonth(runningDate);
        const lastDayOfMonth = getDaysInMonth(runningDate);
        const currentDay = getDate(runningDate);
        const targetDay = getDate(targetDateTime);

        const isMatchingMonth = currentMonth === targetMonth;

        const isLastDayOfMonth = currentDay === lastDayOfMonth;

        const isExactDayMatch = currentDay === targetDay;

        const isTargetingAfterLastDayOfMonth =
          getDate(targetDateTime) > lastDayOfMonth;

        const isExactMatch = isMatchingMonth && isExactDayMatch;

        const isApproxMatch =
          isMatchingMonth && isLastDayOfMonth && isTargetingAfterLastDayOfMonth;

        const isMatch = isExactMatch || isApproxMatch;

        return isMatch;
      }

      default:
        return false;
    }
  };

  const daysToGenerate = differenceInDays(endDate, startDate);

  for (let i = 0; i <= daysToGenerate; i++) {
    const currentDate = addDays(startDate, i);

    const dayTransactions: DatabaseRecord = [];

    items.forEach((item) => {
      const shouldAdd = shouldAddTransaction(item, currentDate);

      if (shouldAdd) {
        dayTransactions.push(item);
      }
    });

    const dayInitialBalance =
      transactionsByDay.length > 0
        ? transactionsByDay[transactionsByDay.length - 1].finalBalance
        : Number(openingBalance);

    const dayNetBalance = dayTransactions.reduce((accumulator, transaction) => {
      const cost = transaction.cost || 0;

      const netCost = transaction.type === income ? cost : -1 * cost;

      return accumulator + netCost;
    }, 0);

    const dayFinalBalance = dayInitialBalance + dayNetBalance;

    transactionsByDay.push({
      fromDate: currentDate,
      initialBalance: dayInitialBalance,
      finalBalance: dayFinalBalance,
      transactions: dayTransactions,
    });
  }

  return transactionsByDay;
};
