import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import {
  Box,
  Button,
  Fieldset,
  Group,
  NativeSelect,
  NumberInput,
  TextInput,
  Title,
} from "@mantine/core";
import { cloneDeep, noop } from "lodash";
import { IconCurrencyPound } from "@tabler/icons-react";
import { saveUserFormData } from "./firebase";
import { isNotEmpty, useForm } from "@mantine/form";
import { DatePickerInput } from "@mantine/dates";
import { AppContext } from "./Context";
import { useContext } from "react";
import {
  ANNUALLY,
  DAILY,
  FOUR_WEEKLY,
  LAST_MONTHLY_WEEKDAY,
  MONTHLY,
  ONE_OFF,
  QUATERLY,
  WEEKDAYS,
  WEEKLY,
} from "./contants";
import { z } from "zod";
import { v4 as uuidv4 } from "uuid";
import {
  DatabaseItem,
  DatabaseRecord,
  DateItem,
  DayNumberItem,
  income,
  NoNumberItem,
  outcome,
  TransactionType,
} from "./types";

// everything in the HTML form is a string, except numerical field `cost`
const formParser = z.object({
  description: z.string(),
  cost: z.number(),
  regularity: z.union([
    z.literal(ONE_OFF),
    z.literal(QUATERLY),
    z.literal(FOUR_WEEKLY),
    z.literal(ANNUALLY),
    z.literal(DAILY),
    z.literal(WEEKDAYS),
    z.literal(LAST_MONTHLY_WEEKDAY),
    z.literal(MONTHLY),
    z.literal(WEEKLY),
  ]),
  recurrenceDate: z.string(),
  recurrenceDayNumber: z.string(),
  uuid: z.string(),
});

export type FormType = z.infer<typeof formParser>;

export const optionsOptions = [
  { label: "Monthly", value: MONTHLY },
  {
    label: "Monthly (Last working day)",
    tableLabel: "Monthly",
    value: LAST_MONTHLY_WEEKDAY,
  },
  { label: "Weekly", value: WEEKLY },
  { label: "Daily", value: DAILY },
  { label: "Daily (Mon-Fri)", tableLabel: "Daily", value: WEEKDAYS },
  { label: "One-off", value: ONE_OFF },
  { label: "Quaterly", value: QUATERLY },
  { label: "Annually", value: ANNUALLY },
  { label: "4 weekly", value: FOUR_WEEKLY },
];

export const weeklyOptions = [
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
  { label: "Sunday", value: "0" },
];

export const monthlyOptions = [
  { label: "1st", value: "1" },
  { label: "2nd", value: "2" },
  { label: "3rd", value: "3" },
  { label: "4th", value: "4" },
  { label: "5th", value: "5" },
  { label: "6th", value: "6" },
  { label: "7th", value: "7" },
  { label: "8th", value: "8" },
  { label: "9th", value: "9" },
  { label: "10th", value: "10" },
  { label: "11th", value: "11" },
  { label: "12th", value: "12" },
  { label: "13th", value: "13" },
  { label: "14th", value: "14" },
  { label: "15th", value: "15" },
  { label: "16th", value: "16" },
  { label: "17th", value: "17" },
  { label: "18th", value: "18" },
  { label: "19th", value: "19" },
  { label: "20th", value: "20" },
  { label: "21st", value: "21" },
  { label: "22nd", value: "22" },
  { label: "23rd", value: "23" },
  { label: "24th", value: "24" },
  { label: "25th", value: "25" },
  { label: "26th", value: "26" },
  { label: "27th", value: "27" },
  { label: "28th", value: "28" },
  { label: "29th", value: "28" },
  { label: "30th", value: "30" },
  { label: "31st", value: "31" },
];

export const Form = ({
  onClose,
  mode,
  items,
  uuid,
  dataKey,
  useMockData,
}: {
  onClose: () => void;
  mode: "edit" | "create";
  items?: DatabaseRecord | null;
  dataKey: TransactionType;
  uuid: string;
  useMockData: boolean;
}) => {
  const { setUserData } = useContext(AppContext);

  const item = items && items.find((item) => item.uuid === uuid);

  interface InitalFormValues extends Omit<FormType, "cost"> {
    cost: number | undefined;
  }

  const { onSubmit, getInputProps, values, setFieldValue } =
    useForm<InitalFormValues>({
      validate: {
        description: isNotEmpty("Description must not be empty"),
        cost: isNotEmpty("Cost must not be empty"),
      },
      initialValues: item
        ? {
            // edit item mode
            description: item.description,
            cost: item.cost,
            regularity: item.regularity,
            recurrenceDayNumber:
              ("recurrenceDayNumber" in item &&
                String(item?.recurrenceDayNumber)) ||
              "",
            recurrenceDate:
              ("recurrenceDate" in item && item.recurrenceDate) || "",
            uuid: item.uuid,
          }
        : {
            // create item mode
            description: "",
            cost: undefined,
            regularity: "MONTHLY",
            recurrenceDayNumber: "1",
            recurrenceDate: "",
            uuid: uuidv4(),
          },
    });

  const typeLabel = dataKey === income ? income : outcome;

  const handleSubmit = async (values: any) => {
    // can't gaurentee the shape of the form input, hence parse the any
    const parsedValues = formParser.parse(values);

    if (mode === "edit" && !uuid) {
      throw new Error("Needs a UUID");
    }

    const originalItems = cloneDeep(items || []);

    const adaptFormToItem = (formValues: FormType): DatabaseItem => {
      const coreProps = {
        description: formValues.description,
        cost: Number(formValues.cost),
        uuid: formValues.uuid,
      };

      if (
        formValues.regularity === ONE_OFF ||
        formValues.regularity === QUATERLY ||
        formValues.regularity === FOUR_WEEKLY ||
        formValues.regularity === ANNUALLY
      ) {
        const result: DateItem = {
          ...coreProps,
          regularity: formValues.regularity,
          recurrenceDate: formValues.recurrenceDate,
          type: typeLabel,
        };

        return result;
      }

      if (
        formValues.regularity === DAILY ||
        formValues.regularity === WEEKDAYS ||
        formValues.regularity === LAST_MONTHLY_WEEKDAY
      ) {
        const result: NoNumberItem = {
          ...coreProps,
          description: formValues.description,
          regularity: formValues.regularity,
          type: typeLabel,
        };

        return result;
      }

      if (
        formValues.regularity === WEEKLY ||
        formValues.regularity === MONTHLY
      ) {
        const result: DayNumberItem = {
          ...coreProps,
          description: formValues.description,
          regularity: formValues.regularity,
          recurrenceDayNumber: Number(formValues.recurrenceDayNumber),
          type: typeLabel,
        };

        return result;
      }

      throw new Error("Unsupported regularity: ", formValues.regularity);
    };

    const adaptedValues = adaptFormToItem(parsedValues);

    const updatedItems =
      mode === "edit"
        ? originalItems.map((i) =>
            i.uuid === adaptedValues.uuid ? adaptedValues : i
          )
        : [...originalItems, adaptedValues];

    await saveUserFormData(updatedItems);

    setUserData(updatedItems);

    onClose();
    return;
  };

  return (
    <Box mx="auto">
      <Title order={2}>
        {mode === "create"
          ? `Add a new ${typeLabel}`
          : `Edit an existing ${typeLabel}`}
      </Title>
      <form onSubmit={onSubmit(useMockData ? noop : handleSubmit)}>
        <Fieldset mt={10}>
          <TextInput
            withAsterisk
            label="Description"
            description="Input description"
            placeholder="Main job"
            {...getInputProps("description")}
          />
          <NumberInput
            withAsterisk
            leftSection={<IconCurrencyPound />}
            label="Amount"
            description="Input description"
            placeholder="Input placeholder"
            {...getInputProps("cost")}
          />

          <NativeSelect
            withAsterisk
            label="Regularity"
            description="Input description"
            data={optionsOptions}
            {...getInputProps("regularity")}
          />

          {values.regularity === MONTHLY && (
            <NativeSelect
              withAsterisk
              label="Date"
              description="Input description"
              data={monthlyOptions}
              {...getInputProps("recurrenceDayNumber")}
            />
          )}

          {values.regularity === WEEKLY && (
            <NativeSelect
              withAsterisk
              label="Date"
              description="Input description"
              data={weeklyOptions}
              {...getInputProps("recurrenceDayNumber")}
            />
          )}

          {(values.regularity === ONE_OFF ||
            values.regularity === QUATERLY ||
            values.regularity === FOUR_WEEKLY ||
            values.regularity === ANNUALLY) && (
            <DatePickerInput
              withAsterisk
              description="Input description"
              label="Pick date"
              placeholder="Pick date"
              {...getInputProps("recurrenceDate")}
              value={
                !values.recurrenceDate || values.recurrenceDate === "undefined"
                  ? null
                  : new Date(values.recurrenceDate)
              }
              onChange={(x) =>
                setFieldValue("recurrenceDate", x?.toISOString() || "")
              }
            />
          )}
        </Fieldset>

        <Group justify="flex-end" mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Box>
  );
};
