import z from "zod";
import {
  ANNUALLY,
  DAILY,
  FOUR_WEEKLY,
  LAST_MONTHLY_WEEKDAY,
  MONTHLY,
  ONE_OFF,
  QUATERLY,
  WEEKDAYS,
  WEEKLY,
} from "./contants";

export const income = "income";
export const outcome = "outcome";

export const description = z.string();
export const type = z.enum([income, outcome]);
export const cost = z.number();
export const uuid = z.string();

export type TransactionType = z.infer<typeof type>;

const baseItem = {
  cost,
  type,
  description,
  uuid,
};

const noNumberItem = z.object({
  ...baseItem,
  regularity: z.union([
    z.literal(DAILY),
    z.literal(WEEKDAYS),
    z.literal(LAST_MONTHLY_WEEKDAY),
  ]),
});

export type NoNumberItem = z.infer<typeof noNumberItem>;

const dayNumberItem = z.object({
  ...baseItem,
  recurrenceDayNumber: z.number().min(0).max(31),
  regularity: z.union([z.literal(MONTHLY), z.literal(WEEKLY)]),
});

export type DayNumberItem = z.infer<typeof dayNumberItem>;

const dateItem = z.object({
  ...baseItem,
  recurrenceDate: z.string().datetime(),
  regularity: z.union([
    z.literal(ONE_OFF),
    z.literal(QUATERLY),
    z.literal(FOUR_WEEKLY),
    z.literal(ANNUALLY),
  ]),
});

export type DateItem = z.infer<typeof dateItem>;

export const itemParser = z.union([noNumberItem, dayNumberItem, dateItem]);

export type DatabaseItem = z.infer<typeof itemParser>;

export const databaseRecord = z.array(itemParser);

export type DatabaseRecord = z.infer<typeof databaseRecord>;

export const transactionsByDayRecordParser = z.object({
  fromDate: z.date(),
  initialBalance: z.number(),
  finalBalance: z.number(),
  transactions: z.array(itemParser),
});

export type TransactionsByDayRecord1 = {
  transactions: DatabaseRecord;
};

export type TransactionsByDayRecord = z.infer<
  typeof transactionsByDayRecordParser
>;

export type Notification = {
  message: string;
  action: "delete" | "edit" | "create";
};
