import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore/lite";
import { GoogleAuthProvider } from "firebase/auth";
import { partition } from "lodash";
import { DatabaseRecord, itemParser } from "./types";

const provider = new GoogleAuthProvider();

const firebaseConfig = {
  prod: {
    apiKey: "AIzaSyBjVqNCkgaX9GGDv-CIbXfsnZPnpFfTLMo",
    authDomain: "moneyflux-prod.firebaseapp.com",
    projectId: "moneyflux-prod",
    storageBucket: "moneyflux-prod.appspot.com",
    messagingSenderId: "855646432227",
    appId: "1:855646432227:web:954a4c4443dc398ba3ced6",
  },
};

const firebase = initializeApp(firebaseConfig.prod);
const firestore = getFirestore(firebase);
export const auth = getAuth();

export const signOutUser = () => signOut(auth);

export const signInUser = async () => {
  await signInWithPopup(auth, provider);
};

export const getUser = async () => {
  const response = await signInWithPopup(auth, provider);
  return response;
};

export const loadData = async (): Promise<DatabaseRecord> => {
  if (!auth.currentUser) {
    console.log("Not signed in");
    return [];
  }

  const docRef = doc(firestore, "users", auth.currentUser?.uid);
  const rawResult = await getDoc(docRef);
  const result = rawResult.data();
  console.log("🚀 ~ loadData ~ rawResult:", result);

  if (!result) {
    return [];
  }

  const unparsed =
    ("form2" in result && result.form2) ||
    ("data" in result && result.data) ||
    ("transactions" && result.transactions) ||
    ("form" in result && JSON.parse(result.form)) ||
    [];

  console.log("🚀 ~ loadData ~ unparsed:", unparsed);

  const safeParseItem = (income: any) => {
    const result = itemParser.safeParse(income);

    if (result.success) {
      return {
        ...result,
      };
    }

    console.log(result.error.errors);

    return {
      result,
      unparseableData: income,
    };
  };

  const [parsedItems, unparsedItems] = partition(
    unparsed.map(safeParseItem),
    (income) => income.success
  );

  if (unparsedItems.length > 0) {
    console.warn("🚀 ~ loadData ~ unparsedItems:", unparsedItems);
  }

  return parsedItems.map((item) => item.data);
};

// TODO: save balance and generation dates?
export const saveUserFormData = async (data: DatabaseRecord) => {
  if (!auth.currentUser) {
    return { success: false, message: "User not authenticated" };
  }

  try {
    const docRef = doc(firestore, "users", auth.currentUser?.uid);

    await setDoc(docRef, { transactions: data }, { merge: true });
    return { success: true };
  } catch (e) {
    console.log("OOOOPSSS!!!", e);
    console.error(e);
    return { success: false, message: e };
  }
};
