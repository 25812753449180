import { noop } from "lodash";
import { Dispatch, SetStateAction, createContext } from "react";
import { DatabaseRecord, Notification } from "./types";

export const AppContext = createContext<{
  setUserData: Dispatch<SetStateAction<DatabaseRecord>>;
  setNotification: Dispatch<SetStateAction<Notification | null>>;
  notification: Notification | null;
}>({
  setUserData: noop,
  setNotification: noop,
  notification: { message: "", action: "delete" },
});
