import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import {
  AppShell,
  Box,
  Button,
  Collapse,
  createTheme,
  Grid,
  Group,
  Modal,
  Text,
  ThemeIcon,
  useMantineColorScheme,
} from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { MantineProvider } from "@mantine/core";
import { DataTable } from "./Table";
import { DataChart } from "./Chart";
import { MantineLogo } from "@mantinex/mantine-logo";
import {
  IconBulb,
  IconChartDots,
  IconChevronDown,
  IconChevronUp,
  IconPlus,
  IconTrendingDown,
  IconTrendingUp,
  TablerIconsProps,
} from "@tabler/icons-react";
import { auth, loadData, signInUser, signOutUser } from "./firebase";
import { useEffect, useState } from "react";
import { Form } from "./Form";
import { AppContext } from "./Context";
import { v4 as uuidv4 } from "uuid";
import { partition } from "lodash";
import {
  DatabaseRecord,
  income,
  Notification,
  outcome,
  TransactionType,
} from "./types";
import { generateMockData } from "./generate-mock-data";

function App() {
  const theme = createTheme({
    // theme overrides go here
  });

  return (
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <InnerApp />
    </MantineProvider>
  );
}

const FakeAccordion = ({
  title,
  Icon,
  children,
  onAddClick,
  itemCount,
  isOpen,
  setIsOpen,
  colour,
}: {
  title: string;
  Icon: (props: TablerIconsProps) => JSX.Element;
  children: JSX.Element;
  onAddClick?: () => void;
  itemCount?: number;
  isOpen: boolean;
  colour?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formattedTitle =
    itemCount !== undefined ? `${title} (${itemCount})` : title;

  return (
    <Grid.Col
      tabIndex={0}
      style={{
        cursor: "pointer",
        borderColor: "var(--mantine-color-dark-4)",
        borderWidth: "1px",
        borderStyle: "solid",
      }}
      px="lg"
      span={12}
    >
      <Box>
        <Group onClick={() => setIsOpen(!isOpen)}>
          <ThemeIcon color={`${colour}.9`}>
            <Icon opacity={0.6} />
          </ThemeIcon>
          <Text>{formattedTitle}</Text>
          <Box ml="auto">
            {onAddClick && (
              <Button
                size="compact-md"
                style={{
                  marginRight: "20px",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onAddClick();
                }}
              >
                <IconPlus />
              </Button>
            )}
            {isOpen ? <IconChevronUp /> : <IconChevronDown />}
          </Box>
        </Group>
        <Collapse
          mt="md"
          transitionDuration={(itemCount || 0) * 10 + 100}
          in={isOpen}
        >
          {isOpen && children}
        </Collapse>
      </Box>
    </Grid.Col>
  );
};

function InnerApp() {
  const { setColorScheme, colorScheme } = useMantineColorScheme();
  const [useMockData, setUseMockData] = useState<boolean>(
    process.env.NODE_ENV === "development" && false
  );
  const [userData, setUserData] = useState<DatabaseRecord>([]);
  const [user] = useAuthState(auth);

  const [incomes, outcomes] = partition(
    userData,
    (item) => item.type === income
  );

  useEffect(() => {
    const load = async () => {
      if (useMockData) {
        const mockItems = generateMockData();
        setUserData(mockItems);
      } else {
        const res = await loadData();
        if (res) {
          setUserData(res);
        }
      }
    };

    load();
  }, [user, useMockData]);

  const AddItemModal = ({
    onClose,
    dataKey,
  }: {
    onClose: () => void;
    dataKey: TransactionType;
    title: string;
  }) => {
    return (
      <Modal size="80%" opened={Boolean(dataKey)} onClose={onClose}>
        <Form
          items={userData}
          mode="create"
          onClose={onClose}
          uuid={uuidv4()}
          dataKey={dataKey}
          useMockData={useMockData}
        />
      </Modal>
    );
  };

  const [notification, setNotification] = useState<Notification | null>(null);
  const [isOpenIncomes, setIsOpenIncomes] = useState(false);
  const [isOpenOutcomes, setIsOpenOutcomes] = useState(false);
  const [isOpenForecast, setIsOpenForecast] = useState(true);
  const [newItemModal, setNewItemModal] = useState<null | TransactionType>(
    null
  );

  return (
    <AppContext.Provider value={{ setUserData, setNotification, notification }}>
      <AppShell header={{ height: 60 }} padding="md">
        <AppShell.Header>
          <Group h="100%" px="md" justify="space-between">
            <MantineLogo size={30} />
            <Group>
              <Button
                onClick={() =>
                  colorScheme === "light"
                    ? setColorScheme("dark")
                    : setColorScheme("light")
                }
              >
                <IconBulb />
              </Button>
              {false && process.env.NODE_ENV === "development" && (
                <Button onClick={() => setUseMockData(!useMockData)}>
                  Use {useMockData ? "real" : "mock"} data
                </Button>
              )}
              {user ? (
                <Button variant="secondary" onClick={signOutUser}>
                  Logout
                </Button>
              ) : (
                <Button variant="secondary" onClick={signInUser}>
                  Login
                </Button>
              )}
            </Group>
          </Group>
          <Group></Group>
        </AppShell.Header>
        <AppShell.Main>
          <Grid>
            <Grid.Col span={12}>
              <FakeAccordion
                title="Forecast"
                Icon={IconChartDots}
                isOpen={isOpenForecast}
                setIsOpen={setIsOpenForecast}
              >
                <DataChart items={userData} />
              </FakeAccordion>
            </Grid.Col>
            <Grid.Col span={6}>
              <FakeAccordion
                title="Incomes"
                itemCount={incomes.length}
                colour="green"
                Icon={IconTrendingUp}
                onAddClick={() => setNewItemModal(income)}
                isOpen={isOpenIncomes}
                setIsOpen={setIsOpenIncomes}
              >
                <DataTable
                  title={"Incomes"}
                  items={userData}
                  dataKey="income"
                  useMockData={useMockData}
                />
              </FakeAccordion>
            </Grid.Col>
            <Grid.Col span={6}>
              <FakeAccordion
                title="Outgoings"
                itemCount={outcomes.length}
                colour="red"
                Icon={IconTrendingDown}
                onAddClick={() => setNewItemModal(outcome)}
                isOpen={isOpenOutcomes}
                setIsOpen={setIsOpenOutcomes}
              >
                <DataTable
                  title={"Outgoings"}
                  items={userData}
                  dataKey="outcome"
                  useMockData={useMockData}
                />
              </FakeAccordion>
              {newItemModal && (
                <AddItemModal
                  onClose={() => setNewItemModal(null)}
                  title="Income"
                  dataKey={newItemModal}
                />
              )}
            </Grid.Col>
          </Grid>
        </AppShell.Main>
      </AppShell>
    </AppContext.Provider>
  );
}

export default App;
